<template>
  <en-table-layout :tableData="pageData.data" :loading="loading">
    <template slot="toolbar">
      <el-form-item label="导入进度" class="col-auto">
        <el-select
          style="width: 120px"
          v-model="advancedForm.status"
          size="medium"
        >
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="导入时间" class="col-auto">
        <el-date-picker
          style="width: 240px"
          v-model="advancedForm.time_range"
          type="daterange"
          size="medium"
          :editable="false"
          unlink-panels
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>

      <el-button
        @click="advancedSearchEvent"
        size="small"
        type="primary"
        class="ml-2"
        >搜索</el-button
      >
    </template>

    <template slot="table-columns">
      <el-table-column align="center" label="导入时间">
        <template slot-scope="{ row }">
          {{ row.export_time }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="导入进度" width="180">
        <template slot-scope="{ row }">
          {{ row.state }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="导入成功数"
      >
        <template slot-scope="{ row }">
          {{ row.import_success }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="导入失败数"
      >
        <template slot-scope="{ row }">
          {{ row.import_fail }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="290">
        <template slot-scope="{ row }">
          <el-button
            type="primary"
            size="small"
            @click="downloadToastData(row.id)"
            v-if="row.schedule !== 'IN'"
            >下载总数据</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="downloadErrorData(row.id)"
            v-if="row.schedule !== 'IN'"
            >下载失败数据</el-button
          >
        </template>
      </el-table-column>
    </template>

    <el-pagination
      slot="pagination"
      @size-change="handlePageSizeChange"
      @current-change="handlePageCurrentChange"
      :current-page="params.page_no"
      :page-size="params.page_size"
      :layout="MixinTableLayout"
      background
      :total="params.data_total"
    ></el-pagination>
  </en-table-layout>
</template>

<script>
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import { getJdNotesList, getJdExportData } from "@/api/goods";
import { unixToDate } from "@/../ui-utils/Foundation";
import { $goodsListTemplate } from "@/pages/goods/services/goods-list";

export default {
  name: "orderList",
  components: {
    EnTableLayout,
  },
  data() {
    return {
      loading: false,
      jdImportName: "商品SKU",
      statusList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "进行中",
          value: "IN",
        },
        {
          label: "已完成",
          value: "COMPLETE",
        },
      ],
      advancedForm: {
        status: "",
        time_range: null,
      },
      pageData: {
        data: [],
      },
      params: {
        page_no: 1,
        page_size: 20,
        data_total: 0,
      },
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    async downloadToastData(id) {
      let { code, message, data } = await getJdExportData(id);
      if (code !== 200) return this.$message.error(message);
      let list = [];
      data.total_data.split(",").forEach((item) => {
        list.push({
          sku: item,
        });
      });
      $goodsListTemplate.exportTemplate(
        list,
        `${unixToDate(data.create_time)}导入总数据`,
        {
          fields: [
            {
              name: this.jdImportName,
              key: "sku",
            },
          ],
        }
      );
    },
    async downloadErrorData(id) {
      let { code, message, data } = await getJdExportData(id);
      if (code !== 200) return this.$message.error(message);
      let list = JSON.parse(data.fail_data);

      $goodsListTemplate.exportTemplate(
        list,
        `${unixToDate(data.create_time)}导入失败数据`,
        {
          fields: [
            {
              name: this.jdImportName,
              key: "sku",
            },
            {
              name: "失败原因",
              key: "err_msg",
            },
          ],
        }
      );
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.params.page_no = 1;
      this.getDataList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.getDataList();
    },

    advancedSearchEvent() {
      this.params.page_no = 1;
      this.getDataList();
    },

    getDataList() {
      this.loading = true;
      const { page_no, page_size } = this.params;
      const { status, time_range } = this.advancedForm;
      let start_time = "";
      let end_time = "";
      if (time_range) {
        console.log(new Date(time_range[0]));
        start_time = time_range[0] ? time_range[0].getTime() / 1000 : "";
        end_time = time_range[1] ? time_range[1].getTime() / 1000 + 86399 : "";
      } else {
        start_time = "";
        end_time = "";
      }
      getJdNotesList({
        page_no,
        page_size,
        start_time,
        end_time,
        schedule: status,
      }).then((res) => {
        const { code, data, message } = res;
        this.loading = false;
        if (code !== 200) return this.$message.error(message);
        this.pageData.data = data.data || [];
        this.pageData.data.forEach((item) => {
          item.export_time = unixToDate(item.create_time);
          item.state = {
            IN: "进行中",
            COMPLETE: "已完成",
          }[item.schedule];
          if (item.schedule === "IN") {
            item.import_fail = item.import_success = "——";
          }
        });

        this.params.data_total = data.data_total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
